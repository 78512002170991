@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.scroll {
  height: 33em;
  width: 100%;
  overflow-y: scroll;
  background-color: #F5F4F5;
}
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.toolPanel {
  text-align: center;
}

.spacer {
  display: inline-block;
  min-width: 20px;
}

#myChart {
  height: 100%;
}

.sidebarNavs {
  background: #00689d;
  width: 500px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100px;
  left: 10%;
  transition: 350ms;
  z-index: 99;
}
.Hcontainer {
  background-color: #000;
  transition: all 0.3s ease;
}
.Hvideo {
  opacity: 0.7;
}
.containter2 {
  width: 50%;
}
.formTitl {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #000000;
}
.estudio {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #c4c4c4;
}
.tit-form {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3643ba;
  justify-content: center;
}
.subtit-form {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #060606;
  justify-content: center;
}
.form-group {
  margin-bottom: 1rem;
}
input.stylePlacer {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  height: 51px;
}
input.stylePlace::-webkit-input-placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  color: #c4c4c4;
}
.disponibilidad {
  margin-top: auto;
}
.cSelect {
  background: #ffffff;
  height: 3em;
  border: 1px solid #ced2d8;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  display: flex;
  align-items: center;
  text-align: justify;
}

.cFile {
  display: inline-block;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.button-wrapper {
  position: relative;
  width: 24%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
}

.button-wrapper span.label {
  position: relative;
  display: inline-block;
  width: 100%;
  background: #e5e5e5;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 600;

  color: #00689d;
}
.pdf-form {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #00689d;
  margin-top: -5px;
}

.button-wrapper span.label {
  padding-top: 9px;
  padding-bottom: 9px;
}
.form-control {
  height: 3em;
}
.jumbotron {
  padding: 1.8rem 0rem;
}
.Enviar {
  position: relative;
  text-align: center;
  width: 100%;
  background: #3643ba;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  color: #00689d;
  border: none;
}
.Enviar:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #00689d;
  transition: all 300ms;
}
.Enviar span {
  position: relative;
  display: inline-block;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.EnviarError {
  position: relative;
  text-align: center;
  width: 100%;
  background: #ff600a;
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  border: none;
}
.EnviarError:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #97200a;
  transition: all 300ms;
}
.EnviarError span {
  position: relative;
  display: inline-block;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.EnviarSuccess {
  position: relative;
  text-align: center;
  width: 100%;
  background: #04b138;
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  border: none;
}
.EnviarSuccess:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #037739;
  transition: all 300ms;
}
.EnviarSuccess span {
  position: relative;
  display: inline-block;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.txt-perfil {
  margin-top: 3em;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
}
.cSelect option {
  position: relative;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 16px;
  margin: 20px;
  padding: 30px;
  color: #000000;
}
.select-option {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  color: #000000;
}

// VACANTES /TEAM

.container3 {
  width: 80%;
  margin: 0 auto 30px;
  
}

.h-maps {
  height: 600px;
}
.cardsVacantes {
  max-height: 540px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mt--3 {
  margin-top: -30px;
}

.tit-vacante {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: justified;
  color: #3643ba;
}
.desc-vacante {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: justified;
}
.ml--2 {
  padding-left: 3em;
}
.btn-ver {
  width: 100%;
  height: 38px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  background: #3643ba;
  border-radius: 2px;
  border: none;
  margin: auto;
  padding-left: 10px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  transition: all 300ms;
}
.btn-ver:hover {
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-seg {
  width: 100%;
  height: 38px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  background: #5184f3;
  border-radius: 12px;
  border: none;
  margin: auto;
  padding-left: 10px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  transition: all 300ms;
}
.btn-seg-green {
  width: 100%;
  height: 38px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  background: #333eac;
  border-radius: 12px;
  border: none;
  margin: auto;
  padding-left: 10px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  transition: all 300ms;
}
.btn-seg-gray {
  width: 100%;
  height: 38px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  background: #8BABFE;
  border-radius: 12px;
  border: none;
  margin: auto;
  padding-left: 10px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  transition: all 300ms;
}
.btn-limpiar {
  width: 100%;
  height: 38px;
  font-weight: 700;
  color: #fff;
  background: #ff600a;
  border-radius: 2px;
  border: none;
  margin: auto;
  padding-left: 10px;
  padding-top: 0.6em;
  font-size: 15px;
  padding-bottom: 0.6em;
  transition: all 300ms;

}
.btn-limpiar:hover {
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-verGris {
  width: 119px;
  height: 38px;
  font-weight: 700;
  color: #fff;
  background: #c4c4c4;
  border-radius: 2px;
  border: none;
  margin: auto;
  padding-left: 10px;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  transition: all 300ms;
}
.btn-verGris:hover {
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ubicacion {
  background: #ffffff;
  border: 1px solid #b3bac3;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}
.ubicacion-icon {
  cursor: pointer;
}
.ubicacion-icon:hover {
  cursor: pointer;
}
.m---1 {
  margin-right: 20px;
}
// DROPDOWN
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;

  float: right;
  width: 20px;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  /* margin-right: -10px; */
  transition: all 500ms;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.dropdown-item:hover {
  color: #fff;
  text-decoration: none !important;
  background-color: #3643ba;
  padding-left: 3px;
  transition: all 500ms;
}

.dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3643ba;
}
.dropdown-item {
  padding-left: 0.1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-right: 0.1em;
  transition: all 500ms;
}

.icon-link img{
  width: 60%;
  margin-right: 0px;
  height: auto;
}

.dropdown-menu.multi-level.show li{
  color: transparent;
}
.icon-link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;

  width: 40px;
}
.item-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.lvl {
  width: 30px;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  width: 7rem;
  margin: auto;
}
.rating div {
  margin: 2px;
  flex: 1;
  align-self: flex-end;
  justify-content: space-evenly;
  background-color: #fff;
  border: 0.2rem solid #3643ba;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}
.rating div:hover {
  background-color: #3643ba;
}
.rating div[data-rating="1"] {
  height: 2rem;
}
.rating div[data-rating="2"] {
  height: 1.5rem;
}
.rating div[data-rating="3"] {
  height: 1rem;
}
.rating div:hover ~ div {
  background-color: #3643ba;
}

.lvl1h {
  background-color: #fff;
  position: relative;
}
.hhh {
  position: absolute;
  overflow: hidden;
}
.lvl2h {
  background-color: #fff;
}
.lvl1h {
  background-color: #fff;
}

.lvl1h:hover {
  background-color: #fff;
}
.lvl2h:hover {
  background-color: #fff;
}
.lvl3h:hover {
  background-color: #fff;
}
.w100 {
  width: 100% !important;
}
.hovers:hover {
  background-color: #e5e5e5;
}
.dfelxx {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.save-assign {
  background-color: #3643ba;
  cursor: pointer;
  padding: 8px;
  color: #fff;
  font-weight: 600;
  position: relative;
  top: 0.5em;
  float: right;
}
@media only screen and (max-width: 715px) {
  .banner-div img {
    height: 141px !important;
  }
  .status-vacants p {
    font-size: 5px !important;
  }


  .status-img-sabri {
    display: none;
  }
  .status-img-sabri-front {
    display: none;
  }
  .rating {
    display: flex;
    flex-direction: row-reverse;
    width: 4rem;
    margin: auto;
  }
  .rating div[data-rating="1"] {
    height: 1.5rem;
  }
  .rating div[data-rating="2"] {
    height: 1rem;
  }
  .rating div[data-rating="3"] {
    height: 0.5rem;
  }
  .redWidth {
    min-width: 0;
    max-width: 100%;
    width: 8em;
  }

  // .dropdown-submenu > .dropdown-menu {
  //   top: 0;
  //   left: 80%;
  //   margin-top: -6px;
  //   margin-left: -1px;
  //   border-radius: 0 6px 6px 6px;
  // }
  // .dropdown-submenu > .dropdown-menu > .dropdown-menu {
  //   top: 0;
  //   left: 90%;
  //   margin-top: -6px;
  //   margin-left: -1px;
  //   border-radius: 0 6px 6px 6px;
  // }
}

#contenedor-chat {
  position: fixed;
  bottom: 0;

  display: none;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 15px 15px 0 0;
  overflow: none;
  align-items: stretch;
  width: 19em;
  left: 80%;
  margin-left: -98px;
  box-shadow: 12px 12px 32px #d5d5d5, -12px -12px 32px #ebebeb;
}
#chat {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}
#caja-chat {
  width: 100%;
  height: 300px;
  padding: 12px;
  // background-color: rgb(236, 241, 255);
}

#datos-chat {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="submit"] {
  height: 40px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
}
textarea {
  height: 40px;
  border-radius: 5px;
}

input,
textarea {
  margin-bottom: 3px;
}
.form-chat {
  padding: 8px 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  box-shadow: 0 -1px 0 0;
}
.col-desc {
  padding: 50px;
}
.col-desc h3 {
  font-size: 30px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justified;
  color: #3643ba;
}
.col-desc h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: justify;
  color: #3643ba;
}
.resp-sel div {
  background-color: #e5e5e5;
  height: 9em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 33px;
}
.descImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height:20em;
}
.resp-sel div p {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #3643ba;
}
.btn-regresar {
  border: none;
  color: #ec660a;
  background-color: #fff;
  width: 15em;
  padding: 5px;
  font-weight: 700;
  margin: 2em;
  position: relative;
  left: 62em;
}
.btn-regresar-admin {
  border: none;
  color: #ec660a;
  background-color: #fff;
  max-width: 7em;
  padding: 8px;
  font-weight: 700;
  margin: 2em;
  position: relative;
}
.btn-aplicar {
  background-color: #ec660a;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 6px 23px;
}
.btn-regresar img {
  width: 0.5em;
  top: -0.1em;
  position: relative;
  padding-right: 2px;
}
.btn-regresar-admin img {
  width: 0.5em;
  position: relative;
  padding-right: 2px;
}

// .container3 {
//   width: 100%;
//   margin: 0 auto;
// }
// .desc-vacante {
//   font-family: Roboto;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 10px;
//   line-height: normal !important;
//   display: block;
//   color: #4e5d6b;
// }
// .btn-ver {
//   width: 100%;
//   height: auto;
//   font-weight: 700;
//   color: #fff;
//   background: #ff600a;
//   border-radius: 2px;
//   border: none;
//   margin: auto;
//   padding-left: 10px;
//   font-size: 10px;
//   line-height: 12px;
//   color: #ffffff;
// }
// .tit-vacante {
//   font-family: Roboto;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 14px;
//   letter-spacing: 0em;
//   text-align: justified;
// }
// .ml--2 {
//   padding-left: 2em;
//   padding-right: 2em;
// }
// .btn-regresar{
//   left: 6em;
// }
// .resp-sel{
//   display: none;
// }

.bg-back {
  background-color: #F5F4F5;
}
.bg-back-front {
  background-color: #e5e5e5;
}
.status-vacants {
  font-size: 20px;
}
.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  top: 1em;
  position: relative;
}
.dot_1 {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  top: -1em;
  position: relative;
}
.dot_not_active {
  background-color: transparent;
  border: 2px solid;
  border-color: #3643ba;
}
.dot_active {
  background-color: #3643ba;
}
.diag_2 {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='blue' /></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}
.diag_1 {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='blue' /></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}
.bar-status-desk {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;

  color: #000000;
}
.p-can-rec {
  position: absolute;
  left: 17%;
  width: 1em;
  text-align: center;
  display: flex;
  line-height: 15px;
  padding-bottom: 10px;
}
.p-can-vis {
  position: absolute;
  left: 24%;
  width: 1em;
  text-align: center;
  display: flex;
  line-height: 15px;
  padding-bottom: 0;
  padding-top: 45px;
}
.p-can-con {
  position: absolute;
  left: 39%;
  width: 1em;
  text-align: center;
  display: flex;
  line-height: 15px;
  padding-bottom: 10px;
  padding-top: 0;
}
.p-can-ent {
  position: absolute;
  left: 54%;
  width: 1em;
  text-align: center;
  display: flex;
  line-height: 15px;
  padding-bottom: 0;
  padding-top: 45px;
}
.p-prop {
  position: absolute;
  left: 67%;
  width: 7em;
  text-align: center;
  display: flex;
  line-height: 15px;
  padding-bottom: 10px;
  padding-top: 0;
}
.p-fin {
  position: absolute;
  left: 69%;
  width: 6em;
  text-align: center;
  display: flex;
  line-height: 15px;
  padding-bottom: 0;
  padding-top: 0px;
}
.wBack {
  width: 80%;
  margin: auto;
}
.bg-imgBack {
  background-color: #4e5d6b;
  width: 100%;
  height: 300px;
}
.subNavBack {
  width: 100%;
  margin: auto;
  background-color: #3643ba;
}
.bhNaranja {
  background-color: #ff600a;
}
.bhNaranja:hover {
  cursor: pointer;
}
.subMenuTxt {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  color: #fff;
}
.subMenuTxtHola {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;

  color: #fff;
}

.subMenuTxtUser {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #fff;
}
.subMenuOpt {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.imgBackUser {
  position: absolute;
  margin-top: -30px;
  z-index: 4;
}
.bgShadow {
  z-index: 0;
  min-height: 700px;
  box-shadow: 1px 0px 20px 9px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 1px 0px 20px 9px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 1px 0px 20px 9px rgba(0, 0, 0, 0.16);
}
.bgShadow2 {
  z-index: 0;
  box-shadow: 1px 0px 20px 9px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 1px 0px 20px 9px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 1px 0px 20px 9px rgba(0, 0, 0, 0.16);
}
.titBack {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #3643ba;
}
.titInputBack {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.titAreaBack {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #3643ba;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
.inputCalendar {
  width: 100%;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  transition: all 100ms;
}
.react-datepicker__header {
  background-color: #3643ba !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff !important;
}
.btnEditar {
  width: 2rem;
  height: 2rem;
  background: #ffffff;
  display: flex;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
}
.btnEditar:hover {
  padding-bottom: 4px;
  cursor: pointer;
  background: rgb(229, 242, 255);
  transition: all 400ms;
}
.iconEditar {
  text-align: center;
}
.btnEliminar {
  color:#fff;
  width: 2rem;
  height: 2rem;
  background: #3643BA;
  display: flex;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
}
.btnOpen {
  width: 2rem;
  height: 2rem;
  background: #58FA58;
  display: flex;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.13);
}
.btnEliminar:hover .btnOpen:hover {
  padding-bottom: 4px;
  cursor: m;
  background: #83d2ff;
  transition: all 400ms;
}
.iconEliminar {
  text-align: center;
}

.modalW {
  max-width: 80%;
}
.bgBlue {
  background-color: #3643ba;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem 0rem -1rem auto;
}

.drpdw {
  box-shadow: 18px 17px 24px -8px rgba(102, 102, 102, 0.28);
  -webkit-box-shadow: 18px 17px 24px -8px rgba(102, 102, 102, 0.28);
  -moz-box-shadow: 18px 17px 24px -8px rgba(102, 102, 102, 0.28);
}
.shadowss {
  -webkit-box-shadow: 0px 0px 7px -2px rgba(59, 59, 59, 0.36);
  box-shadow: 0px 0px 7px -2px rgba(59, 59, 59, 0.36);
}
.Session {
  color: #363636;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
}
.actv {
  font-size: 1.2em;
  font-weight: 500;
}
.bgGray {
  background-color: #c4c4c4;
}
.bgBlue {
  background-color: #3643ba;
  transition: all 800ms;
}
.base {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3643ba;
  justify-content: center;
  padding: 20px;
}
.title-jobs {
  font-size: 20px;
}
.img-nivel {
  width: 4em;
  position: relative;
  left: 10px;
}
.select-change .css-26l3qy-menu {
  position: relative;
  z-index: 999999 !important;
}
.title-inn {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  justify-content: center;
}
.title-inn--l {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ec660a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  justify-content: center;
}
.title-inn--m {
  font-weight: 900;
  font-size: 24px;
  line-height: 47px;
  display: block;
  align-items: center;
  text-align: center;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.innovacion {
  background: rgba(0, 104, 157, 0.65);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 60px 0;
}
.innovacion .row {
  justify-content: center;
  padding: 0 160px;
}
.inn-p {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #ffffff;
  width: 570px;
  margin: 0 auto;
}
.fondoimagen1 {
  background-image: url("styles/fundacion_1.jpg");
  height: 619.3px;
  background-size: 100%;
}
.fondoimagen1 p {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 557px;
  float: right;
  position: relative;
  top: 100%;
}
.acc-1 img {
  width: 5em;
  height: 5em;
}

.acc-2 {
  background: #3643ba;
  border: 2px solid #3643ba;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 250px;
  padding: 31px 17px 0px;
  height: 372px;
}
.acc-2 p {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  color: #ffffff;
}
.acc-2 img {
  width: 3em;
  height: 3em;
}
.acc-1 {
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 250px;
  height: 372px;
  padding: 31px 17px 0px;
}
.acc-1 p {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  color: #000;
  text-shadow: none;
}
.vid-p {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #3643ba;
  padding: 35px 0 15px 0;
}
.row-vid {
  justify-content: center;
}
.vid-dig {
  width: 560px;
}
.carrusel {
  display: none;
}
.row-carr {
  padding: 40px 0;
}
.banner-div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.banner-p {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #3643ba;
  height: 0.4em;
  position: absolute;
  left: 6em;
  top: 7em;
}
.desc-div {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #000000;
  padding: 1em 0 0 13.5em;
}
.titl-1-div {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3643ba;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 0 0 5em;
}
.titl-2-div {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  text-shadow: -1px -1px 1px #3643ba, 1px -1px 1px #3643ba, -1px 1px 1px #3643ba,
    1px 1px 1px #3643ba;
  padding: 0 0 0 8em;
}
.p-3-div {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #000000;
  padding: 0 0 0 12em;
}
.senior-img {
  justify-content: center;
  align-items: center;
  display: flex;
}
.banner-div img {
  width: 100%;
  height: 537px;
}

.bar-status-resp {
  display: none;
}
.diverso-p p {
  color: #4e5d6b;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.diverso-p {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.diverso-div {
  padding-bottom: 2em;
  align-items: center;
  text-align: center;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 55px 15px;
}

@media only screen and (max-width: 915px) {
  .btn-chat button {
    top: 2em;
    position: relative;
  }
  .diag_2 {
    top: -1em;
    position: relative;
  }
  .diag_1 {
    width: 40px;
    height: 150px;
    top: -0.5em;
    position: relative;
  }
  .dot {
    left: -1em;
    top: -0.5em;
  }
  .dot_1 {
    left: 2em;
    top: -1em;
  }
  .p-can-vis {
    left: 30%;
    padding-bottom: 0;
    padding-top: 0;
  }
  .p-can-con {
    left: 30%;
    padding-bottom: 10px;
    padding-top: 0;
  }
  .p-can-ent {
    left: 30%;
    padding-bottom: 0;
    padding-top: 0;
  }
  .p-prop {
    left: 30%;
    padding-bottom: 10px;
    padding-top: 0;
  }
  .p-fin {
    left: 30%;
    padding-top: 0;
  }
  .p-can-rec {
    left: 30%;
  }
  .bar-status-desk {
    display: none;
  }
  .bar-status-resp {
    display: block;
    padding: 0 35px !important;
  }

  .img-disc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .titl-2-div {
    font-size: 18px;
    padding: 0 0 0 8em;
    line-height: 0;
  }
  .titl-1-div {
    font-size: 18px;
  }
  .p-3-div {
    padding: 0;
  }
  .banner-p {
    left: 1em;
    top: 4em;
    font-size: 33px;
  }

  .desc-div {
    padding: 0;
  }
  .carousel-item {
    left: 6em;
  }
  .row-carr {
    display: none;
  }
  .carrusel {
    display: block;
    padding: 3em 0;
  }
  .carousel-control-prev-icon {
    background-color: #007dbc;
  }
  .carousel-control-next-icon {
    background-color: #007dbc;
  }
  .fondoimagen1 {
    height: 300px;
  }
  .vid-dig {
    width: 80%;
  }
  .row-vid .vid-p {
    display: none;
  }
  .fondoimagen1 p {
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%);
    width: 60%;
    position: relative;
    top: 7em;
    float: none;
    margin: 0 auto;
  }
  .fondoimagen1 {
    background-image: url("styles/foundation.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .innovacion .row {
    padding: 0 0;
    margin-left: 0 !important;
    width: 100%;
  }
  .inn-p {
    width: 312px;
  }
  .inn-video {
    width: 80%;
  }
  .inn-img {
    width: 73%;
    display: flex;
    margin: 0 auto;
  }
  .item-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    word-wrap: break-word;
  }

  .container3 {
    width: 100%;
    margin: 0 auto 30px;
  }
  .desc-vacante {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: normal !important;
    display: block;
    color: #4e5d6b;
  }
  .btn-ver {
    width: 100%;
    height: auto;
    font-weight: 700;
    color: #fff;
    background: #3643ba;
    border-radius: 2px;
    border: none;
    margin: auto;
    padding-left: 10px;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }
  .btn-limpiar {
    width: 100%;
    height: auto;
    font-weight: 700;
    color: #fff;
    background: #ff600a;
    border-radius: 2px;
    border: none;
    margin: auto;
    padding-left: 10px;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;

  }
  .tit-vacante {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: justified;
  }
  .ml--2 {
    padding-left: 2em;
    padding-right: 1;
  }
  .btn-regresar {
    left: 6em;
  }
  .resp-sel {
    display: none;
  }
  .title-jobs {
    font-size: 10px;
  }
  .title-nivel {
    font-size: 12px;
  }
  .img-nivel {
    width: 33px;
  }
  #contenedor-chat {
    left: 40%;
  }
  .subMenuTxtHola {
    font-size: 14px;
  }
  .subMenuTxt {
    font-size: 13px;
  }
  .titBack {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    color: #3643ba;
  }
}

.status-hola {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;

  color: #ec660a;
}
.status-img-sabri {
  width: 100%;
  height: 100%;
}
.status-img-sabri-front {
  width: 80%;
  height: 100%;
}
.status-name {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;

  color: #00689d;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.status-email {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
}
.status-desc {
  font-family: Roboto;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: justify;
}
.status-vacante {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  color: #3643ba;
}
.status-vacants p {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 44px;
  text-align: justify;
  color: #000000;
}
.hiddenRow {
  padding: 0 !important;
}
.btn-chat {
  padding-bottom: 50px;
}
.btn-chat button {
  width: 405px;
  padding: 10px;
  background-color: #c4c4c4;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.btn-perfil {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;

  color: #000000;
}

.btn-admin {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  padding: 10px;
  color: rgb(233, 233, 233);
  background-color: #ec660a;
  border-color: #db5903;

  box-shadow: 0 0 8px 0.1rem rgb(255 117 34 / 57%);
}

.btn-admin:hover {
  color: #fff;
  background-color: #e05d06;
  border-color: #db5903;
  box-shadow: 0 0 8px 0.1rem rgb(255 117 34 / 57%);
  transition: all 0.5s ease;
}
.btn-admin:focus {
  outline: 0;
  box-shadow: 0 0 8px 0.2rem rgb(255 117 34 / 57%);
}
.maxWidthCirculos {
  position: relative;
  max-width: 1200px;
}
.selectedBuso {
  position: absolute;
  width: 80px;
  margin-top: -14em;
}
.selectedText {
  position: absolute;
  margin-top: -6em;
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #3643ba;
}
.ptProgress {
  padding-top: 11em;
  padding-bottom: 2em;
}
.selectedlvl {
  width: 100%;
}
.buttonStatus {
  width: 247px;
  height: 62px;

  background: #c4c4c4;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: auto auto 20px;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease;
}
.buttonStatus:hover {
  background-color: #3643ba;
  padding-left: 5px;
  transition: all 0.6s ease;
}

.buttonStatusDisabled {
  width: 247px;
  height: 62px;
  background: #c4c4c4;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: auto;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  transition: all 0.4s ease;
}

.buttonStatusWarning {
  width: 247px;
  height: 62px;
  background: #ff600a;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: auto;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  transition: all 0.4s ease;
}

.buttonStatusOrange:hover {
  background-color: #3643ba;
  padding-left: 5px;
  transition: all 0.6s ease;
}
.buttonStatusBlue {
  width: 247px;
  height: 62px;
  cursor: pointer;

  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: auto;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #3643ba;
}
.buttonStatusOrange {
  width: 247px;
  height: 62px;
  cursor: pointer;

  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin: auto;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: #ff600a;
  transition: all 0.4s ease;
}

.btnMoverCandidato {
  width: 405px;
  height: 38px;
  color: fff;
  background: #c4c4c4;
  border-radius: 2px;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.statusVacantes {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 28px;
  line-height: 44px;
  cursor: pointer;
  color: #000000;
}

.statusVacantes div div{
  font-size: 0.7em;
}

.statusVacantes:hover {
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 28px;
  line-height: 44px;
  cursor: pointer;
  padding-left: 10px;
  background-color: #c4c4c4;
  transition: all 0.4s ease;
}
#profile-img {
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
  clip-path: polygon(0 0, 0 100%, 100% 83%, 100% 0);
}
.bg-actv {
  color: #e7e7e7;
  background-color: #3643ba;
  transition: all 0.6s ease;
}
.ovy {
  height: "240px" !important;
  overflow-y: "scroll" !important;
}
.bg-tableTH {
  border: 1pt solid #3643BA;
  background-color: #3643BA;
  padding-top: 0;
  color:#fff;
}
.btnMoverCandidato:hover {
  color: #e7e7e7;
  padding-left: 5px;
  cursor: pointer;
  background-color: #db5903;
  transition: all 0.6s ease;
}
.text-errors {
  font-size: 0.8em;
  font-weight: 500;
  font-family: Roboto;
  font-style: italic;
  padding-left: 10px;
}
.link-order {
  cursor: pointer;
}
#map {
  z-index: 0;
}
.no-vacante {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #4e5d6b;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.20);
}
.btn-vacante {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  background: #ec660a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.7s ease;
  margin-bottom: 3em;
}
.btn-vacante:hover {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 12px;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.7s ease;
}
.btn-vacante-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}
.cursor-pointer {
  cursor: pointer;
}
.shows {
  display: block;
}
.container-fluid.bg-back{
  padding-right: 0px;
}
.forgot-tit {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;

  color: #000000;
}
.forgot-desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.forgot-enviar {
  background: #ff600a;
  text-align: center;
  border: 0;
  max-width: 250px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 26px;
  padding-right: 16px;
}
.forgot-crear {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #3643ba;
}
.loggin {
  margin-top: "1em";
  width: "420px" !important;
}
.btn-editar-status {
  border-radius: 2px;
  color: #fff;
  background: #ff600a;
  text-align: center;
  border: 0;
  max-width: 150px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 26px;
  padding-right: 16px;
  cursor: pointer;
  transition: all 400ms;
}

.btn-editar-status:hover {
  padding-left: 10px;
  background: #ff772e;
  transition: all 400ms;
}
.btn-cancelar-status {
  border-radius: 2px;
  color: #fff;
  background: #ce3812;
  text-align: center;
  border: 0;
  max-width: 180px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 26px;
  padding-right: 16px;
  cursor: pointer;
  transition: all 400ms;
}

.btn-cancelar-status:hover {
  padding-left: 10px;
  background: #ff772e;
  transition: all 400ms;
}
.btn-actualizar-status {
  border-radius: 2px;
  color: #fff;
  background: #3643ba;
  text-align: center;
  border: 0;
  max-width: 180px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 26px;
  padding-right: 16px;
  cursor: pointer;
  transition: all 400ms;
}

.btn-actualizar-status:hover {
  padding-left: 10px;
  background: #0173ac;
  transition: all 400ms;
}
.input-status {
  margin: 0;
  background-color: transparent;
  padding: 5px 5px 5px 0;
  font-size: 18px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  max-height: 30px;
  transition: all 400ms;
}
.input-status:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  box-shadow: none;
}
.select-status {
  font-size: 18px;
}

.select-text:focus ~ .select-label,
.select-text:valid ~ .select-label {
  color: #2f80ed;
  top: -20px;
  transition: 0.2s ease all;
  font-size: 14px;
}
.aling-center-status {
  align-items: center;
}
.aling-col-left {
  margin-left: -20px;
}
.button-wrapper-user {
  position: absolute;
  width: 50%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  vertical-align: middle;
  z-index: 3;
  height: 37px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 400ms;
  font-size: 15px;
}
.button-wrapper-user {
  background: #3643ba;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;

  font-family: Roboto;
  font-style: normal;
  font-weight: 600;

  color: #fff;
}

.button-wrapper-user:hover {
  padding-left: 20px;
  background: #0173ac;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 15px;
  transition: all 400ms;
}
.wmodalresp {
  max-width: 80%;
}
.tit-accordeon{
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.75rem 1.85rem;
}
.paginacion{
  text-align: center;
  margin: auto;
  justify-content: center;
   font-size: 12px;
}

.spacePre{
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
}


@media only screen and (max-width: 915px) {
  .forgot-tit {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 21px;
  }
  .loggin {
    width: "360" !important;
  }
  .aling-col-left {
    margin-left: 0px;
  }
  .wmodalresp {
    max-width: 100%;
  }
  .btnMoverCandidato {
    max-width: 305px;
     height: 38px;
     font-weight: 500;
     font-size: 14px;
     line-height: 18px;
   }
   .status-email {
    font-size: 15px !important;
  }
  .status-name {
    font-size: 16px !important;
  }
  .status-desc {
    font-size: 15px !important;
  }
  .status-vacante {
    font-size: 15px !important;
  }
  .status-hola {
    font-size: 18px !important;
  }
  .buttonStatus {
    max-width: 190px;
    height: 52px;
    font-size: 14px;
    transition: all 0.4s ease;
  }
  .buttonStatusOrange {
    max-width: 190px;
    height: 52px;
    font-size: 14px;
    transition: all 0.4s ease;
  }
  .statusVacantes {
    font-size: 18px;
    line-height: 27px;
  }
  .statusVacantes:hover {
    font-size: 18px;
    line-height: 27px;
    transition: all 0.4s ease;
  }
  .paginacion{
    text-align: center;
    margin: auto;
    justify-content: center;
     font-size: 12px;
  }
  @media (max-width: 767px) {
    .containter2 {
      width: 90%;
    }
    .form-row{
      flex-direction: column;
    }
    .button-wrapper{
      width: 100%;
      margin: 10px 0;
    }
  }
}
