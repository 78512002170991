@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

html,
body {
  height: 100%;
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  top: 0;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.row{
  margin-right: 0px !important;
}

footer,
section#main,
body {
  width: 100%;
}
section {
  margin: 0;
  padding: 0;
}

/* section#main {
  min-height: 100%;
} */

footer {
  bottom: 0;
  z-index: 100;
  background-color: #3643BA;
  color: #fff;
  margin-top:30px;
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  padding-top: 16px;
  padding-bottom: 8px;
}
.footer span{
 font-size: 0.9em;
}

.foo{
  margin-left: 50px;
}
.icon_social{
  width: 3em;
  margin: 10px;
}
.dropdown{
  cursor: pointer;
  z-index: 9;
}
.Oleft {
  text-align: justify;
  margin-top: 1%;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  margin-left: 10%;
}

.Oleft,
.Oright {
  flex: 1;
}
.Oright {
  margin-left: 10%;
}

.Ocenter {
  text-align: center;
}
.Icenter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 12px;
  margin-top: 4 0px;
}
.footer a {
  text-decoration: none;
  font-weight: 700;
}

.icon {
  width: 15px;
  margin: 5px;
}
.icon2 {
  margin: 10px;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.content {
  flex: 1 0 auto;
}
.alert-info {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border: 1px solid #faebcc;
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0;
}
.hamburger {

  color: #3643ba !important;
  font-size: 2em;
  transition: all 0.3s ease;

}
.somos-deporte {
  background-color: #00689d !important;
  bottom: 0;
  display: flex;
  position: absolute;
  width: 100%;
  padding: 0 5px 0 20px;
  justify-content: space-around !important;
}

.img-nav {
  width: 11%;
}

.img-nav-1 {
  width: 35%;
}

.cont-img {
  display: flex;
  font-size: 11px;
  font-weight: 700;
  flex-wrap: wrap;
  width: 10em;
  justify-content: center;
  color: #fff;
}

.cont-img span {
  font-size: 2em;
  letter-spacing: 2px;
}
.cont-img:hover {
  color:#d1daf0;
  transition: color .5s ease;
}


.img-banner {
  height: 28em;
  width: 100%;
}
.home {
  display: block;
}
.decatips {
  width: 100%;
  height: 55px;
  background: #3643ba;
  text-align: center;
  font-weight: 700;
  color: #fff;
  font-size: 22px;
  line-height: 55px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.decatips:hover {
  color: #fff;
}
.content-map {
  width: 100%;
  height: -webkit-fill-available;
  display: flex;
}
.map {
  width: 40%;
  padding: 15px;
  height: inherit;
}
.search {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}

#mapCanvas {
  width: 100%;
  height: 400px;
}
.select {
  width: 256px;
  font-size: 15px;
}
.jobs {
  font-size: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 716px;
  height: 116px;
  left: 623px;
  top: 270px;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.scroll-jobs {
  overflow-y: scroll;
  height: 5em;
  position: relative;
  align-self: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 80%;
}
.input {
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* height: 2em; */
}

.jobs h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: justify;
  padding-bottom: 5px;
  color: #007dbc;
}

.jobs-description {
  width: 500px;
}

.jobs-description p {
  font-size: 12px;
}

.btn-search {
  width: 43px;
  height: 41px;
  background: #3643ba;
  border-radius: 2px;
  border: none;
  cursor: pointer;
}

/* ¡ÚNETE A NUESTRO EQUIPO! */
.perfil {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
}
.unete {
  position: relative;
  width: 789px;
  height: 32px;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  align-items: center;
  text-align: center;
  color: #00689d;
  margin: 0 auto;
  margin-bottom: 55px;
}

.input {
  display: flex;
  justify-content: space-around;
  width: 327px;
  height: 38px;
  border: 1px solid #b3bac3;
  box-sizing: border-box;
  border-radius: 2px;
}
.nombre {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nav {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: space-between !important;
  padding: 0 10px;
  height: 100px !important;
  width: 80%;
  margin: 0 auto;
  background-color: #F5F4F5 !important;
}
.pl-02 {
  padding-left: 10px;
}
.pl-03 {
  padding-left: 90px;
}
.pl-04 {
  padding-left: 12px;
}
.pr-03 {
  padding-right: 90px;
}
.pr-1 {
  margin-right: 15%;
}
.va-t{
  vertical-align: center;
  padding-bottom: 9px;

}
.va-b {
  vertical-align: bottom;
  padding-top: 7px;
}
.logo-decathlon{
  width: 300px;
  padding-left: 9px;
  padding-top: 7px;
  margin-right: auto;
}
.banner {
  display: flex;
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;

}
.videoTag {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: -1;
  margin-top: -2%;
  filter: brightness(70%);

}
.txts {
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
}
.txt-img-l {
  display: flex;
  font-family: Roboto;
  font-size: 64px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: rotate(-10deg);
  vertical-align: middle;
  align-items: center;
}
.txt-img-r {
  display: flex;
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-size: 44px;
  line-height: 56px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  align-self: flex-end;
  padding-bottom: 15%;
  text-align: left;
  max-width: 352px;
}
.btn_signin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 40px; 
  border-radius: 50%;
  border: none; 
  background-color: #3643ba;
  cursor: pointer; 
  padding: 0;
}

.btn_signin_img {
  width: 20px;
  height: 25px;
}
.btn-register {
  position: relative;
  top: 30px;
  width: 100% !important;
  margin: 0 auto;
  margin-bottom: 2em;
}

.container-email{
  display: flex;
  justify-content: space-between;
}

.btn-b {
  width: 352px;
  height: 54px;
  background: #3643ba;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  display: flex;
  align-content: flex-end;
  justify-content: space-around;
  align-items: center;}
  .btn-b:hover{
    color: #fff;
    cursor: pointer;
  }
.pt-1 {
  padding-top: 11px;
}
.pt-2 {
  padding-top: 6px;
}
.pt-33 {
  padding-top: 2%;
}
.pb-2 {
  padding-bottom: 400px;
}
.val-1 {
  align-items: center; /* <---- NEW    */
}
.fs-24 {
  font-size: 34px;
  font-weight: bold;
}

.pb-1 {
  padding-bottom: 10px;
}
.h100 {
  height: 128px !important;
  padding: 0 16%;
}


@media only screen and (max-width: 790px) {
  .footer {
    flex-direction: column;
  }
  
  .logo-decathlon  {
    width: 150px !important;
  }
}
@media only screen and (max-width: 950px) {
  .va-b {
    display: none;
  }
}

.showSidebarNo {
  left: "0";
}
.showSidebarSi {
  left: "-100%";
}

/* terminan las media querys */

.right {
  display: flex;
}

.flex-nw {
  display: flex;
  flex-direction: column;
  max-width: 352px;
}
.span {
  font-size: 16px;
  text-decoration: none;
  color: gray;
  margin: 0 10px;
}

.offer{
  background-color: #3643BA;
  border-radius: 30px;
  color: white;
  padding: 10px 20px; /* Ajusta el padding según sea necesario */
  text-align: center; /* Centra el texto horizontalmente */
  display: inline-block; 
}
.offer-mb{
  background-color: #3643BA;
  color: #fff !important;
}
a:link,
a:visited,
a:active {
  text-decoration: none;
}
.formacion .select {
  width: 327px !important;
}

.deporte-pasion .select {
  width: 218px !important;
}

.upload {
  width: 327px;
}

.contacto .select {
  width: 327px;
}
.contacto .inp-file {
  width: 50%;
}
.send {
  position: relative;
  width: 677px;
  height: 64px;
  margin: 0 auto;
  background: #3643ba;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
}

@media only screen and (max-width: 640px), (max-device-width: 640px) {
  .perfil {
    width: 80%;
  }
  .nombre {
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .deporte-pasion.nombre {
    flex-wrap: wrap !important;
  }
  .contacto.nombre {
    flex-wrap: wrap !important;
  }
  .contacto.nombre input {
    width: 50% !important;
  }
  .datos.nombre input {
    width: 62px;
  }
  .contacto .select {
    width: 50% !important;
  }
  .contacto button {
    width: 50% !important;
  }
  .datos.nombre .select {
    margin: 0 10px;
  }
  .send {
    width: 100%;
  }
}

/*SUSTENTABLE*/
.Container-sust {
  width: 100%;
}
.bg-banner-sust {
  background: url("../components/images/sust1bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  object-fit: none;
}
.video-sust {
  margin-top: 4%;
  margin-bottom: 4%;
}
.sust-title {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sust-desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.item-1sust {
  width: 40%;
}

/* SUST/ MATS VERDE */

.mats {
  background-color: #88c698;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  object-fit: none;
}
.item-mats {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 25%;
  margin-bottom: 1%;
}

.icon-res {
  align-items: center;
  align-content: center;
  width: 20%;
}
.titl-mats {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}
.desc-mats {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.iframe-container {
  display: none;
}
.container-eco {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.gif-eco {
  width: 20%;
}
.desc-eco {
  width: 40%;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

.pensando-img {
  display: none;
}

.pensando-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  width: 80%;
  margin: auto;
  color: #88c698;
}

@media screen and (max-width: 900px) {
  .video-sust {
    display: none;
  }
  .centerIframe {
    width: 90%;
    margin: 7% auto;
  }

  .iframe-container {
    display: block;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9*/
    position: relative;
  }

  .iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .sust-title {
    margin-top: 7%;
    font-size: 18px;
    line-height: 21px;
  }
  .sust-desc {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7%;
  }
  .item-1sust {
    width: 75%;
  }
  .bg-banner-sust {
    background: url("../components/images/sust2bg.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    object-fit: none;
  }

  .titl-mats {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
  }
  .desc-mats {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
  }

  .icon-res {
    margin-bottom: -10px;
    align-items: center;
    align-content: center;
    width: 50%;
  }
  .desc-eco {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    width: 80%;
    margin: auto;
  }
  .gif-eco {
    display: none;
  }
  .pensando-img {
    width: 15%;
    display: block;
    margin: 20px auto;
  }
  .pensando-txt {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;

    color: #88c698;
  }
}

/*  CARRERA */

/* .carrera-bg-img {
  background: url("../components/images/bgcamp.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
}
.bg-color-transparent {
  background: rgba(0, 104, 157, 0.85);
}

.h3-carrera {
  display: flex;
  justify-content: space-around;
  font-family: Roboto;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  list-style: none;
  text-decoration: none;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 1px;
  -webkit-text-fill-color: #9acd3200;
  -webkit-text-stroke: 1px white;
  color: #e1e9fc;
  transform: rotate(-10deg);
}
.h3-deca {
  display: flex;
  justify-content: space-around;
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;

  transform: rotate(-10deg);
  margin-top: -35px;
  margin-left: 160px;
}
.py-carrera-1 {
  padding-top: 6em;
  padding-bottom: 3em;
}
.py-carrera-2 {
  margin-top: 5;
}
.pb-carrera-1 {
  padding-bottom: 3em;
}
.carrera-txt-1 {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #fefefe;

  width: 60%;
  margin: 0 auto;
}

.carrera-txt-2 {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: #fefefe;
}
.Slick-Slider {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 5em;
}
.mAuto {
  margin: auto;
  width: 90%;
}
@media screen and (max-width: 900px) {
  .Slick-Slider {
    width: 80%;
  }
} */
/* DECATIPS */
.decatips-container {
  position: relative;
  width: 100%;
}
.decatips-imgG {
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
}
.decatips-imgS {
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
  display: none;
}
@media screen and (max-width: 900px) {
  .decatips-imgG {
    display: none;
  }
  .decatips-imgS {
    display: block;
  }
  .decatips {
    display: none;
  }
}

.innovacion {
  position:relative;
  background: url('../components/images/back_1.jpg') no-repeat fixed;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  min-height: 500px;
  padding: 0;
}
/*CULTURA*/

.cultura {
  position:relative;
  background: url('../components/images/bgcamp.png') no-repeat fixed;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	min-height: 500px;
}

.cultura-white {
  background-color: #fff;
  width: 100%;
}
.cultura-valores {
  background: url("../components/images/bgcamp.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
}
.bg-color-transparent {
  background: rgba(0, 104, 157, 0.85);
}
.flex-cntnt {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  margin-left: 6%;
  margin-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.flex-cntnt-cult {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-items: center;
  align-content: center;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
}
.py-1 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.py-2 {
  padding-top: 3em;
  padding-bottom: 3em;
}
.variant-cntnt-colab {
  align-items: flex-end;
  padding-top: 16px;
  padding-bottom: 16px;
}
.cultura--one {
  margin-bottom: 2em;
}
.cultura--one h3 {
  width: 100%;
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.h3-cultura {
  padding-bottom: 10px;
}
.hp-cultura {
  max-width: 18em;
}
.video-1 {
  vertical-align: middle;
  padding-left: 20px;
}
.video-2 {
  vertical-align: middle;
  margin-left: -70px;
}
.cultura--one p {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
}
@media screen and (max-width: 1450px) {
  .video-1 {
    width: 12%;
  }
  .video-2 {
    width: 12%;
  }
}
.item-colaboradores {
  text-align: center;
  vertical-align: middle;
}
.txt-h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 42px;
  color: rgba(0, 130, 195, 0.8);
}
.txt-h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 130, 195, 0.8);
}
.txt-h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 130, 195, 0.8);
}
.nuestros4 {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  display: flex;
  justify-content: space-around;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.txt-cult-empresa {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: justify;
  width: 50%;
  margin: auto;
  color: #ffffff;
  padding-bottom: 2em;
}
.gigant-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 170px;
  line-height: 199px;

  color: rgba(255, 255, 255, 0.4);
}
.txt-negative {
  margin-top: -60px;
}

.txt-naranja {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  color: #ec660a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.txt-white-val {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.flex-cntnt-val {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
  align-items: flex-start;
}
.iframe-cult {
  position: relative;
  width: 760px;
  height: 420.27px;
  margin: auto;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.title-selection{
  font-size: 20px;
  font-weight: 600;
  color: #3643ba;
}
.filtro-btn{
  padding: 10px;
  height: 47px;
  margin: auto 0 0;
  background-color: #3643ba;
  border:none;
  color:#fff;
}
@media screen and (max-width: 900px) {
  .cultura-valores {
    background: url("../components/images/bgcamp.png");
  }
  .ocultar900 {
    display: none;
  }
  .video-1 {
    vertical-align: middle;
    padding-left: 10px;
    width: 9%;
  }
  .video-2 {
    vertical-align: middle;
    margin-left: -40px;
    width: 8%;
  }
  .item-colaboradores {
    width: 40%;
    padding-bottom: 10px;
  }
  .variant-cntnt-colab {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .video-1 {
    width: 9%;
  }
  .video-2 {
    width: 6%;
  }
}

.cultura--two h3 {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cultura--two {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
}

@media screen and (max-width: 1200px) {
  .nav {
    height: 56px !important;
  }

.sidebarNavs{
  width: 400px;
  top: 56px;
  left: 0;
}
  .aling-centermin {
    text-align: center;
  }
  .cultura--one {
    margin-bottom: 0.5em;
  }

  .cultura--one h3 {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
  .cultura--one p {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
  .cultura--one iframe {
    display: none;
  }
  .nuestros4 {
    font-family: Roboto;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
  }
  .gigant-num {
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
  }
  .txt-naranja {
    font-family: Roboto;
    font-size: 18px;
    font-style: italic;
    font-weight: 900;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
  }

  .txt-cult-empresa {
    width: 90%;

    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: justified;
  }
  .txt-negative {
    margin-top: -20px;
  }

  .txt-white-val {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 3px;
  }

  .cultura--two h3 {
    font-size: 18px;
    line-height: 21px;
  }

  .cultura--two p {
    font-size: 14px;
    line-height: 16px;
  }
  .iframe-cult {
    position: relative;
    width: 282px;
    height: 156px;
    margin: auto;
  }
  .txt-h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 130, 195, 0.8);
  }
  .txt-h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 130, 195, 0.8);
  }
  .txt-h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    color: rgba(0, 130, 195, 0.8);
  }
}
.iframe-container2 {
  overflow: hidden;
  padding-top: 56.25%; /* 16:9*/
  position: relative;
}

.iframe-container2 iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   padding: 2em;
}

.not-found{
  background-image: url("../components/images/sust1bg.png");
  background-size: cover;
  height: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.not-found h1{
  color: #fff;
}
.btn-404{
  background-color: #3643ba;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17em;
  font-size: larger;
}
/* NUEVOS */

.image-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 35em; /* Ajusta la altura mínima según el contenido */
  background: linear-gradient(to bottom, #a6a6a6 0%, #fff 4%, transparent 50%, transparent 75%, transparent 100% );
  position: relative; /* Cambiado a relative para evitar problemas de superposición */
}

.image-middle-container {
  width: 30em;
  background-color: #3643BA;
  color: #fff;
  padding: 108px 10px 10px;
  height: 35em; /* Ajustado para que la altura sea flexible */
}

.qs_header p.green{
  font-size: 1.3em;
  width: 100%;
}
.qs_header .flecha{
  width: 25px;
}
.qs_header h1{
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 25px;
}
.green {
  color: #7AFFA6 !important;
}

.image-1 {
  width: 35%;
  height: 30em; /* Ajustado para mantener proporciones */
  margin-top: 3em;
}

.image-3 {
  width: 35%;
  height: 26em; /* Ajustado para mantener proporciones */
  margin-top: 4em;
}

.text-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 50px;
  flex-direction: column;
  background-color: #3643BA;
  padding: 17% 10%;
  color: #fff;
}
.text-middle p{
  font-size: 2.3em;
  font-weight: 700;
}
.img-1 img{
 padding: 3% 0;
}
.img-2 img{
  position: relative;
  top: 3.3em;
}

.text-middle p:nth-child(2) {
  text-align: center;
  font-size: 1.7em;
  margin-top: 15%; 
  font-weight: 500;
}

.descubre {
  font-weight: 400;
  margin-top: 10px; 
}

.btn-offer {
  background-color: #fff;
  border-radius: 30px;
  color: #3643BA;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  margin-top: 3em; 
}

.bann-mobile {
  display: none;
}

.text-hide-mobile {
  display: block;
}

.background-image {
  width: 100%;
  height: 300px;
  /* background-image: url("../components/images/sentido-foto-1.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

iframe {
  width: 100%;
  height: 100%;
}

.move-people {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 35em; 
  padding: 5% 0 0 10%;
}

.video-overlay {
  position: absolute;
  top: 60%;
  left: 10%;
  transform: translateY(-50%);
  width: 51%;
  z-index: 1; 
  height: 22em;
}

.move-people img {
  width: 38%;
  height: auto;
  object-fit: cover; 
  position: relative;
  margin-left: 14%;
  padding: 50px;
}

.move-people .title{
  font-weight: 600;
  font-size: 1.5em;
}
.blue{
  color: #3643ba;
  font-size: 2.5em;
  font-weight: 900;
  text-align: right;
}
.move-people-div{
  width: 29em;
    line-height: normal;
}
.move-people-div-mobile{
  display: none;
}
.por_que_tu{
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 100px;
}

.porque_tu_div  li{
  list-style: none;
  font-size: 1.8em;
  font-weight: 600;
  line-height: 2.3em;
}

.porque_tu{
  color: #3643ba;
  font-weight: 900;
  font-size: 2.5em;
}

.porque_tu_div {
  text-align: center;
}

.carrusel-imagen {
  margin-top: 20px;
  text-align: center;
}

.carrusel-imagen img {
  width: 100%;
  max-width: 600px; 
  border-radius: 10px; 
}

ul {
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
  margin: 5px 0;
  color: blue; 
}

li:hover {
  text-decoration: underline; 
}

.cultura_empresa{
  background-color: #3643BA;
  padding: 6% 20%;
  text-align: center;
  justify-content: center;
  display: flex;
  color: #fff;
  flex-wrap: wrap;
}
 .cultura_empresa .title{
  width: 100%;
  font-size: 3.5em;
  font-weight: 700;
  line-height: 50px;
 }
 .cultura_empresa .title .p{
  margin-left: 19%;
    display: flex;
    flex-wrap: wrap;
 }

 .cultura_empresa .span{
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: #7AFFA6;
  margin-left: 26%;
  display: flex;
 }

 .circle_cultura{
  background-color: black;
  height: 25em;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .quienes-somos .icon{
 background-color: #3643ba;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    width: 23px;
    color: #fff;
    font-weight: 600;
    display: inline-flex;
    font-weight: 500;
 }

 .col-circle{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
 }
 .circle{
  background-image: url("../components/images/circulo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13em;
  height:13em ;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
 }

 .circle .text{
  font-size: 2em;
    margin: 0px auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
 }

 .circle p{
  font-size: 4em;
  margin: 0;
 }
.number{
  font-size:1.5em !important;
}
.images-top{
  position: relative;
  top: -10em;
}
.quienes-somos{
  padding-top: 10%;
  padding-right: 10%;
}

.quienes-somos .title{
  font-size: 3em;
  font-weight: 600;
  text-align: center;
}
.images-quienes{
  display: flex;
  justify-content: space-around;
}

.quienes-somos img{
 width: 100%;
}

.images-quienes img{
  width: 22%;
}

.quienes-somos .title_mobile{
  display: none;
}

.link_quienes a{
  font-weight: 600;
  color: #3643ba;
}
.carrera .carrera-img{
  background-color: #F5F4F5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
}

.carrera .carrera-text{
  background-color: #3643BA;
  color: #fff;
  padding: 15% 10% 22% 17%;
}
.carrera-text b{
  font-size: 1.5em;
}

.link{
  text-align: end;
  display: flex;
  padding: 35px 0 0 0;
}

.carrera-text .p{
  font-size: 2.5em;
  font-weight: 700;
  margin: 0 23%;
}
.carrera-text .span{
  color: #7AFFA6;
  font-size: 1em;
    font-weight: 700;
    margin-left: 10%;
}
.carrera .carrera-img img{
  width: 85%;
  margin: 5% 0 5% 25%;
  z-index: 9;
}
.flecha{
  width: 5%;
}

.tips .mobile{
  display: none;
}

.tip_desc{
  background-color: #3643BA;
  color: #fff;
  width: 100%;
  padding: 15px;
  margin-top: 10px;
}

.tips_sec{
  

  background-image: url("../components/images/tips-foto.jpg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 49%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% auto;
    height: 45em;
}
.tips_sec .title{
  width: 100%;
  font-size: 2.7em;
  font-weight: 700;
  line-height: 50px;
 }
 .tips_sec .title .p{
  margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    color: #3643BA;
 }

 .tips_sec .span{
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  color: #000;
  margin-left: 32%;
  display: flex;
 }

 .tips p{
  margin: 0 5%;
 }

.tip_desc .circle_num{
  background-image: url("../components/images/tips-circulo.svg");
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  width: 23px;
  color: #7AFFA6;
  font-weight: 600;
  display: inline-flex;
}
.title-desktop{
  display: block;
}
.quienes-somos .images-top .carousel{
  display: none;
}

.foo_mobile{
  display: none;
}
.modal-body{
  height: 30em;
}
.error{
  color:red;
}

.content-modal {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
  transition: all 0.3s ease;
}
.content-modal {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
}

.content-modal.show {
  opacity: 1;
  transform: translateY(0);
}

.content-modal.hide {
  transform: translateY(-20px);
}
.drop-img{
  width: 2em;
  display: block;
}

.qs_header{
  background-color: #3643BA;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 16% 21%;
  text-align: center;
}

.quienes_somos img{
  width: 100%;
  padding: 0 0 30px 0;
  height: 78%;
}
.accesible .azul{
  color: #3643BA;
  font-size: 2.5em;
  width: 20em;
  display: flex;
  font-weight: 900;
}
.accesible .acc-col{
  padding: 0 10%;
}

.accesible .title{
  background-color: #3643BA;
    color: #fff;
    font-weight: 700;
    width: fit-content;
    padding: 10px;
}
.accesible .video{
  width: 90%;
  height: 71%;
  position: absolute;
  left: -3em;
  top: 17%;
}

.accesible .back-pasto{
  width: 100%;
  padding-left: 35%;
}  
.acc-col p:nth-child(1){
  font-size: 1.3em;
    width: 20em;
    display: flex;
    font-weight: 500;
    margin: 0;
} 
.sustentabilidad{
  width: 100%;
  background-color: #3643ba;
  color: white;
  padding: 3%;
}
.sustentabilidad p{
  font-weight: 900;
  font-size: 3em;
}
.sustentabilidad p:nth-child(1){
  text-align: center;
  margin: 0 0 0 -5em;
}
.sustentabilidad p:nth-child(2){
  padding-left: 37%;
  margin: 0;
  color: #7AFFA6;
}
.sustentabilidad-back{
  background-image: url("./fondo_sustentabilidad.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.susten{
  padding-top: 3%;
}
.susten .video-sust{
  width: 90%;
  margin: 0 auto;
  display: flex;
}
.susten .title{
  font-size: 2em;
  font-weight: 800;
  color: #3643ba;
  margin:0;
}

.susten .title.tit{
  margin-left: 23%;
}

.susten .desc{
  padding-right: 72px;
}

.sust-img{
  display: flex;
  padding: 6em 0 35em 0;
  justify-content: center;
}
.sust-img img{
  width: 50%;
  margin: 0 auto;
  display: flex;
}

.sust-img p{
  margin: 0 28%;
  text-align: center;
}
.img-desktop{
  display: block;
}
.img-mobile{
  display: none;
}
.back-inno{
  background-image: url("./back-innovacion.jpg");
  background-repeat: no-repeat ;
  background-size: cover;
  padding: 17% 0;
  text-align: center;
}
.mobile-inn{
  display: none;
}
.back-gray{
  background-color: #F5F4F5;
  padding: 10%;
}
.innova col{
  padding:0 ;
}
.innova .back-inno p:nth-child(1){
  font-size: 2.5em;
  font-weight: 800;
}
.innova .back-inno p:nth-child(2){
  font-size: 2.5em;
  font-weight: 800;
  color: #3643BA; 
}

.diversidad{
  background-color: #3643BA;
  color: #fff;
  padding: 8% 15% 8% 14%;
}
.diversidad .title p:nth-child(1){
  font-size: 2.5em;
  font-weight: 800;
}
.diversidad .title p:nth-child(2){
  font-size: 2.5em;
  font-weight: 800;
  color: #7AFFA6;
  margin-left: 20%;
}
.text-center .img-div{
  background-color: #3643BA;
  height: 100%;
  width: 69%;
}
.img-div img{
  width: 30em;
  height: 30em;
  top: 10%;
  position: relative;
  left: -4em;
}
.white-div{
  background-color: #fff;
  height: 100%;
}
.map_ini{
  display: flex;
  padding: 2% 5% !important;
  background-color: #3643ba;
}
.select {
  width: 25%;
  margin-right: 1em;
}
.bubble{
  display: none !important;
}

.team-map .title{
  font-size: 2.5em;
  font-weight: 900;
  text-align: center;
  background-color: #F5F4F5;
  padding: 2% 0;
}
.team-map .title p{
  margin: 0;
}
.team-map .title p:nth-child(1){
  color:#3643ba;
}
.team-map .contain{
  flex-direction: row-reverse;
  background-color: #f5f4f5;
  margin-top: 0 !important;
}
.justify-content-center{
  /* margin-bottom:2em; */  
}
.diversidad .title{
  display: flex;
  flex-wrap: wrap;
}
.text-center-back .title{
  display: none;
}
.row-foo{
  align-items: center;
}

.table-bordered th, .table-bordered td{
  border: 1px solid #F5F4F5;
}
td.hiddenRow{
  background-color: #dae2f5 !important;
}
.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody{
  border: none !important;
}
.table-striped tbody tr:nth-of-type(odd){
  background-color: #f5f4f5 !important;
}
/* CARRERA */
.back-carrera{
  background-color: #3643BA;
  color: #fff;
  padding: 10em 6em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.carrera-header .back-img{
  padding: 40px 0;
}
.carrera-header .back-img img{
  width: 100%;
  height: 34em;
}
.back-carrera h3{
  color: #fff;
  font-weight: 600;
  font-size: 2.5em;
  
}
.back-carrera .title{
  line-height: 10px;
  margin-bottom: 3em;
}
.back-carrera .carr-desc p{
  font-size: 1.3em;
}

.back-carrera h3:nth-child(1){
  position: relative;
  left: -2em;
}
.back-carrera h3:nth-child(2){
  position: relative;
  left: 2em;
}
.video-selector ul {
  list-style: none;
  padding: 0;
  display: flex;
  top: -7em;
  position: relative;
  justify-content: center;

}
.historia .p-desc{
  background-color: #f5f4f5;
  padding: 5em 6em;
  text-align: center;
  font-weight: 500;
}

.video-selector li {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color:#000;
}

.historia {
  padding: 5em 0;
}

.historia .video-col {
  position: relative; /* Necesario para el posicionamiento del fondo */
}

.video-container {
  position: relative;
  width: 100%;
  background-image: url('../components/images/textura.jpg'); /* Ruta a la imagen de textura */
  background-size: cover;
  background-position: center;
  padding: 20px;
  height: 36em;
}

.video-title {
  font-size: 2rem;
  margin: 0;
  text-align: left;
  z-index: 1; /* Aseguramos que el título quede encima del fondo */
}

.youtube-video {
  width: 100%;
  max-width: 640px;
  margin-top: 20px;
  position: relative;
  z-index: 1; /* El video debe estar encima de la textura */
  left: 4em;
}
.youtube-video iframe{
  max-width: 640px;
  margin-top: 4em;
  position: relative;
  z-index: 1;
  height: 22em;
}

.historia .text-images-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px; 
}

.video-selector {
  margin-bottom: 20px;
  padding-top: 4em;
}
.video-selector h2:nth-child(1){
  font-size: 1.3rem;
}

.video-selector h2:nth-child(2){
  color: rgb(54, 67, 186);
    left: 20%;
    position: relative;
    font-weight: 900;
    font-size: 2.5em;
    width: fit-content;
}


ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

li img {
  width: 3em;
  height: 3em;
  margin-right: 10px;
}

li:hover {
  font-weight: bold;
}
.entrena{
  background: linear-gradient(to bottom, #3643ba, #f5f4f5);
  padding-top: 4em;
}

.entrena .entrena-title{
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color:#fff;
}
.entrena .entrena-title h2:nth-child(2){
  font-size: 3em;
  font-weight: 900;
}
.entrena .entrena-title h2:nth-child(1){
  font-size: 1.5em;
  font-weight: 900;
  left: -10%;
  position: relative;
}
.entrena .entrena-img div{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: justify;
}
.entrena .entrena-img p{
  padding: 10px 49px;
  text-align: center;
}
.entrena .entrena-img img{
  width: 80%;
}
.entrena-img {
  padding-bottom: 5em;
}
.historia .text-mobile{
  display: none;
}

@media (max-width: 768px) {
  .historia {
    flex-direction: column;
  }

  .historia .video-col,
  .historia .text-images-col {
    width: 100%;
  }

  .historia .text-images-col {
    padding-left: 0;
  }

  .youtube-video {
    width: 100%;
    left: 0;
  }

  h2 {
    text-align: center;
  }
}

.list-group-item{
  color: #000;
}

.dropdown-menu.dropdown-menu-left.drpdw.animate__animated.animate__fadeIn.animate__faster.show.modal_login{
  width: 350px !important;
}

@keyframes moverImagen {
  0% {
    transform: translateY(10px); /* Posición inicial */
  }
  50% {
    transform: translateY(0); /* Mueve la imagen 300px a la derecha */
  }
  100% {
    transform: translateY(10px); /* Vuelve a la posición original */
  }
}

/* Clase para la imagen animada */
.imagen-animada {
  
  height: auto; /* Mantener la proporción */
  animation: moverImagen 2s ease-in-out infinite; /* Aplica la animación de moverImagen */
}

.text-images-col .img-click{
 width: 8em;
 height: 8em;
}

@media (max-width: 768px) {
  .dropdown-menu {
    position: static; 
    width: 100% !important; 
    max-width: 100%; 
    left: 0 !important; 
    border-radius: 0; 
    box-shadow: none; 
  }
  .icon-link{
    width: 50px;
  }

  .dropdown-submenu {
    position: static; 
    display: block; 
    width: 100%; 
  }

  .dropdown-item {
    padding: 10px; 
    font-size: 1em;
  }

  .dropdown-menu .redWidth li{
    padding: 0 16px;
  }
  .rating{
    width: 6em;
  }

  .rating div {
    display: inline-block;
    width: 33.33%; 
    text-align: center;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
 

  .text-hide-mobile {
    display: block;
  }
  .img-2 img{
    top:-70px;
  }

  .img-1 img{
    padding: 0;
  }
  .btn-conoce{
    z-index: 9;
    top: 15em;
    position: relative;
  }
  .imagen-animada{
    z-index: 9;
    top: 16em;
    position: relative;
  }
  .text-middle {
    padding: 20px 48px;
    /* height: 12em; */
  }
  .bann-mobile {
    display: flex;
  }

  .image-1, .image-3 {
    display: none;
  }

  .image-middle-container {
    padding: 35px 0px 0px;
  }

  .image-1_1 {
    width: 100%;
  }

  .bann-mobile a {
    position: absolute;
    left: 14%;
    top: 24em;
  }

  .text-middle {
    padding: 0px 48px;
  }
  .bubble{
    display: flex !important;
  }

  .text-middle h1 {
    font-size: 2em;
  }

  .text-middle p:nth-child(2) {
    font-size: 1.1em;
    margin-top: 8px;
    font-weight: 400;
  }
  .text-middle p{
    font-size: 2em;
  }

  .descubre {
    display: none;
  }

  .move-people{
    margin: 0;
    position: relative;
  }
  .video-overlay{
    height: 13em;
    width: 85%;
    top: 20%;
    left: 9%;
  }

  .move-people img{
    width: 85%;
    height: auto;
    object-fit: cover;
    position: relative;
    padding-left: 0%;
    top: 3em;
    position: relative;
    margin-left: 14%;
  }
  .move-people .title{
    font-size: 1em;
  }

  .blue{
    font-size: 1.8em;
    text-align: left;
  }

  .move-people-div-mobile{
    display: block;
    margin: 10%;
  }

  .move-people-div-mobile .title{
    font-weight: 600;
  }

  .move-people-div{
    display: none;
  }

  .por_que_tu{
    flex-wrap: wrap;
    padding-top: 6em;
    padding-bottom: 0;
    
  }
  .porque_tu{
    font-size: 2em;
  }
  .porque_tu_div li{
    list-style: none;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1em;
  }
  .cultura_empresa{
    padding: 30% 6% 5%;
  }
  .cultura_empresa .title .p {
    font-size: 0.5em;
    margin-left: 20%;
  }
  .cultura_empresa .span {
    margin-left: 15%;
  }

  .cultura_empresa .title{
    line-height: 37px;
  }
  .circle p{
    font-size: 3em;
  }
  .circle{
    background-size: 9em;
    margin-bottom: 0;
    width: 10em;
  }
  .circle_cultura{
    height: auto;
  }
  .text{
    font-size: 1em;
  }
  .number{
    font-size:1em !important;
  }

  .images-quienes img{
    width: 80%;
    margin-right: 25px;
    
  }
  .quienes-somos .mob-img{
    height: 30em;
    position: absolute;
    right: 11.33%;
    width: auto;
  }
  .quienes-somos .title{
    display: none;
  }

  .quienes-somos .title_mobile{
    display: block;
    font-size: 2em;
    text-align: center;
    font-weight: 600;
  }

  .images-top{
    position: relative;
    top:0;
    left: 45px;
  }

  .carrera .carrera-img img{
    width: 92%;
    margin: 5% 0 -78px;
    z-index: 9;
  }

  .carrera-text .p{
    font-size: 2em;
    margin: 0;
  }
  .carrera .carrera-text{
    padding: 30% 10% 40% 10%;
  }
  .carrera-text b{
    font-size: 1.5em;
  }
  .flecha{
    width: 11%;
  }

  .title-desktop{
    display: none;
  }

  .tip_desc .circle_num{
    margin-bottom: 16px;
  }

  .proceso .mobile{
    display: block;
  }
  .tip_desc .num-mob{
    margin-bottom: 0;
  }
  .tips{
    height: auto;
    text-align: center;
  }
  .tips .mobile{
    display: block;
    width: 100%;
    height: auto;
  }

  .tips_sec{
    height: 59em;
    background-image: none;
  }
  .tip_desc{
    height: auto;
  }
  .tips_sec .title{
    display: none;
  }

  .quienes-somos .images-top .images-quienes{
    display: none;
  }
  .quienes-somos .images-top .carousel{
    display: block !important;
  }

  .carousel {
  display: flex;
  align-items: center;
}

.carousel-item {
  margin: 0 20px;
  transition: opacity 0.5s ease;
  left: 32%;
}

.carousel-item {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  @include transition($carousel-transition);
}

button {
  cursor: pointer;
}

.quienes-somos img{
  width: 13em;
}
.icon-soc{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.icon_mobile{
  width: 16px;
  margin: 0 5px 22px;
}
.foo{
  margin: 0;
}

.row-foo{
  flex-wrap: nowrap;
  align-items: center;
}
.drop-img{
  width: 2.5em;
  display: "block";
}
.foo_mobile{
  display: flex;
  justify-content: center;
}
.qs_mobile{
  display: flex;
  flex-direction: column-reverse;
}
.quienes_somos img{
  padding: 0;
  height: 100%;
}
.qs_header{
  padding: 3% 8%;
}
.qs_header h1{
  margin-bottom: 0;
  font-size: 1.5em;
}
.qs_header p.green{
  font-size: 1em;
}
.accesible .video {
  width: 85%;
  height: 71%;
  position: absolute;
  left: 10px;
  top: 12%;
  margin: 0 5%;
}
.sustentabilidad-back{
  background-image: url("./fondo_sustentabilidad_mobile.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.sustentabilidad p {
  font-size: 1.5em;
}
.sustentabilidad{
  padding: 3%;
}
.sustentabilidad p:nth-child(1) {
  text-align: center;
  margin: 0 0 0 -5em;
}
.sustentabilidad p:nth-child(2) {
  padding-left: 23%;
}
.susten .desc{
  padding: 10%;
}
.susten .title{
  font-size: 1.5em;
}
.desc p{
  font-size: 0.9em;
}
.sust-img{
  margin: 5% 0 73%
}
.sus-mobile{
  display: flex;
}
.sus-mobile-none{
  display: none;
}
.acc-col p:nth-child(1){
  font-size: 1em;
}
.accesible .azul{
  font-size: 1.5em;
  width: auto;
}
.img-desktop{
  display: none;
}
.img-mobile{
  display: flex;
}
.sust-img{
  margin: 0;
  padding: 0;
}
.col-img{
  margin: 0 30% 69%;
}
.sust-img p{
  margin: 0 14%;
}
.susten .desc{
  padding: 0 10%;
}
.mobile-inn{
  display: block;
  width: 100%;
}
.back-inno{
  background-image: none;
  background-color: #F5F4F5;
  padding: 5%;
  text-align: center;
}
.innova .video-innova{
  flex-direction: column-reverse;
}
.innova .back-inno p:nth-child(1){
  font-size: 1.5em;
}
.innova .back-inno p:nth-child(2){
  font-size: 1.5em;
}
.div-row{
  flex-direction: column-reverse;
}
.diversidad{
  padding: 8%;
}
.diversidad .title{
  display: none;
}
.text-center-back{
  background-color: #3643BA;
}
.text-center-back .title{
  display: flex;
  flex-wrap: wrap;
  padding: 2% 6%;
  justify-content: center;
}
.text-center-back .img-div{
  width: 100%;
}
.img-div img{
  width: 100%;
  left: 0;
}
.text-center-back .title p:nth-child(1){
  font-size: 2em;
  font-weight: 800;
  color: #fff;
}
.text-center-back .title p:nth-child(2){
  font-size: 2em;
  font-weight: 800;
  color: #7AFFA6;
}
.select{
  width: 50%;
}
.historia{
  padding: 5em 0 0;
}

.historia .text-mobile{
  display: block;
}

.back-carrera{
  padding: 3em 2em;
}
.back-carrera h3{
  font-size: 2em;
}
.back-carrera h3:nth-child(1){
  left: -1em;
}
.back-carrera h3:nth-child(2) {
  left: 1em;
}
.carrera-header .back-img{
  padding: 0 0;
}
.carrera-header .back-img img{
  height: 25em;
}
.video-selector h2:nth-child(1){
  display: none;
}
.video-selector h2:nth-child(2){
  display: none;
}
.historia .text-mobile{
  padding: 15px !important;
}
.historia .text-mobile .video-selector- h2{
 font-size: 1.5em;
}
.text-images-col .img-click{
  width: 5em;
  height: 5em;
 }
 .video-container{
  padding: 0;
  height: 31em;
 }
 .text-images-col.img-click{
  padding: 0;
 }
 .text-images-col.img-click .video-selector{
  flex-direction: column-reverse;
  display: flex;
}
.video-selector ul{
  top: 0;
  padding-left: 15px;
}
.video-selector ul li{
  text-align: center;
}
.historia .p-desc{
  padding: 2em;
}
.video-selector{
  padding-top: 0;
}
.youtube-video iframe{
  height: 16em;
  margin-top: 7em;
}
.entrena .entrena-title h2:nth-child(2){
  font-size: 2em;
}
.entrena .entrena-title p{
  text-align: center;
}
.entrena-img p{
  color: #fff;
}
.entrena-img p#nth{
  color: #000;
}
}


.form-date{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.form-date input{
  border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 3em;
}

.error-container {
  position: relative; 
  display: inline-block; 
  width: 100%;
}

.cerrarError {
  position: absolute;
  top: -10px; 
  right: -10px; 
  width: 30px;
  height: 30px;
  border-radius: 50%; 
  background-color: #f44336; 
  color: white; 
  font-size: 18px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border: none; 
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s ease; 
}

.cerrarError:hover {
  background-color: #d32f2f; 
}
